import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import SubscriptionBar from '../components/SubscriptionBar';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../utils/normalize.css';
import '../utils/css/screen.css';

const ElementsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  const { books } = data;

  const url = location.href ? location.href : '';

  const flavor = [
    'feels like spreading butter on toast',
    'feels like sliding into freshly cleaned sheets',
    'feels oddly satisfying',
    'feels like heaven for your pencil',
    'feels like taking a bubble bath with your fingers',
    'feels like ASMR for your hand',
    'feels great',
    'feels real good',
    'feels like your pen and paper are old friends reuniting',
  ];

  const randomFlavor = () => flavor[Math.floor(Math.random() * flavor.length)];

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Sketchbooks"
        keywords={['sketchbooks', 'art', 'animation', 'hand-drawn']}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="images">Activity books</h2>
          <p className="home-page-excerpt no-margin">
            For the doodlers and GIF-curious.
          </p>
          {books.edges.map(({ node }) => (
            <>
              <figure className="kg-card kg-image-card">
                <Link to={`/books${node.fields.slug}`}>
                  <Img
                    fluid={node.frontmatter.thumb.childImageSharp.fluid}
                    className="kg-image"
                  />
                  <figcaption>{node.frontmatter.title}</figcaption>
                </Link>
                <p>
                  {node.frontmatter.quickDescription}
                  <div className="row">
                    <div className="col" />
                    <div className="col">
                      <a href={node.frontmatter.purchaseLink} className="button print primary large fit buy-me-pulse" style={{ fontWeight: 600 }}>
                        {node.frontmatter.purchaseLabel}
                      </a>
                    </div>
                    <div className="col" />
                  </div>
                </p>
              </figure>
            </>
          ))}
          <hr />
          <h2 id="images">Sketchbooks</h2>
          <p className="home-page-excerpt">
            Animate anywhere, anytime.
          </p>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.sketchbookOrange.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>Arteater Sketchbook - 7.5 x 10.5&quot;</figcaption>
            <p>
              Filled with
              <Link to="/nine-frame-fg-bg/"> nine-frame animation templates.</Link>
              <br />
              Fits perfectly in your backpack. Use on the bus, train, or just sitting on the couch.
              <br />
              Printed on 100% post-consumer waste paper.
              <br />
              Drawing in this sketchbook
              {' '}
              {randomFlavor()}
              .
              <figcaption className="bar-alert">
                <a href="#" className="button primary pill">
                  <strong>$35 - Check back soon</strong>
                </a>
                <br />
                <br />
                (Currently out of stock)
              </figcaption>
            </p>
          </figure>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.sketchbookRed.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>Arteater Sketchbook - 5 x 7&quot;</figcaption>
            <p>
              Pocket-sized booklet filled with
              <Link to="/six-frame-loop/"> six-frame templates.</Link>
              <br />
              Printed on 100% post-consumer waste paper.
              <br />
              Drawing in this sketchbook
              {' '}
              {randomFlavor()}
              .
              <br />
              <figcaption className="bar-alert">
                <a href="#" className="button primary pill">
                  <strong>$25 - Check back soon</strong>
                </a>
                <br />
                <br />
                (Currently out of stock)
              </figcaption>
            </p>
          </figure>
          <hr />
          {/* <div className="row">
            {books.edges.map(({ node }) => {
              return (
                <div
                  className="col-3"
                  key={node.fields.slug}
                >
                  <figure className="kg-card kg-image-card">
                    <Link to={`/books${node.fields.slug}`}>
                      <Img
                        fluid={node.frontmatter.thumb.childImageSharp.fluid}
                        className="kg-image"
                      />
                      <figcaption>{node.frontmatter.title}</figcaption>
                    </Link>
                    <p>
                      <figcaption className="bar-alert">
                        <a href={node.frontmatter.purchaseLink} className="button primary pill">
                          <strong>{node.frontmatter.purchaseLabel}</strong>
                        </a>
                      </figcaption>
                    </p>
                  </figure>
                </div>
              );
            })}
          </div> */}

          <SubscriptionBar
            prompt="Subscribe to receive email updates when sketchbooks become&nbsp;available."
            subscriptionId="sketchbooks-3x5-mockup"
            referenceUrl={url}
          />
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    sketchbookOrange: file(
      relativePath: { eq: "sketchbook-orange.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 92, maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sketchbookRed: file(
      relativePath: { eq: "sketchbook-red.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 92, maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    books: allMarkdownRemark(
      sort: { fields: [frontmatter___order,frontmatter___date,], order: ASC },
      filter: { 
        fileAbsolutePath: {regex : "\/books/"} ,
        frontmatter: { ignore: { ne: true } }
      },
      limit: 99,
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            purchaseLink
            purchaseLabel
            quickDescription
            details
            thumb {
              childImageSharp {
                fluid(maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ElementsPage location={props.location} data={data} {...props} />
    )}
  />
);
